






















































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName } from '@/env';
import { readLoginError, readLoginErrorMessage } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public appName = appName;

  public get loginError() {
    return readLoginError(this.$store);
  }

  public get loginErrorMessage() {
    return readLoginErrorMessage(this.$store);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      dispatchLogIn(this.$store, {username: this.email, password: this.password});
    }
  }
}
